<template>
  <div class="allergyInfResult">
    <h1>AllergyInfPage</h1>
    <p>Your AllergyInf will be displayed here.</p>
  </div>
</template>

<script>
export default {
  name: "AllergyInfResultPage",
};
</script>

<style scoped>
.allergyInfResult {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: green;
}

</style>